<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start mb-2">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        v-b-modal.modal-create
        size="lg"
        :disabled="isDisabled"
      >
        Создать проект
      </b-button>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="getProjects"
      :rtl="direction"
      :bordered="false"
      class="projects-table"
      styleClass="vgt-table"
    >
      <div slot="emptystate" class="text-center">
        Пока не создано ни одного проекта
      </div>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'name'" class="text-nowrap ">
          <div
            class="text-nowrap cursor-pointer text-primary ml-2 mt-2"
            @click="
              isDisabled
                ? $router.push({ name: 'pages-pricing' })
                : $router.push({
                    name: 'project-edit',
                    params: { id: props.row.id, name: props.row.name },
                  })
            "
          >
            {{ props.row.name }}
          </div>
        </span>

        <!-- Column: Shows -->
        <span
          v-else-if="props.column.field === 'shows'"
          style="align-self: center"
        >
          <div class="text-nowrap mt-2 text-center">{{ props.row.shows }}</div>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span class="d-flex justify-content-around">
            <span style="align-self: center;">
              <b-button
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="outline-primary"
                pill
                @click="
                  $router.push({
                    name: 'project-preview',
                    params: { id: props.row.id, name: props.row.name },
                  })
                "
              >
                <feather-icon icon="PlayIcon" /> Предпросмотр
              </b-button>
            </span>
            <span style="align-self: center;" class="d-none d-md-flex">
              <b-button
                :id="`insertion-button${props.row.id}`"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="outline-dark"
                @click="showWidgetModal(props.row.id)"
                pill
              >
                <feather-icon icon="CodeIcon" /> Код виджета
              </b-button>
            </span>
            <span style="align-self: center;" class="mx-2 mx-md-0">
              <feather-icon
                icon="Edit2Icon"
                size="18"
                class="text-body align-middle mr-25 cursor-pointer"
                @click="
                  isDisabled
                    ? $router.push({ name: 'pages-pricing' })
                    : $router.push({
                        name: 'project-edit',
                        params: { id: props.row.id, name: props.row.name },
                      })
                "
              />
            </span>
            <span style="align-self: center;" class="mx-2 mx-md-0">
              <feather-icon
                class="cursor-pointer"
                icon="BarChart2Icon"
                @click="
                  $router.push({
                    name: 'project-statistics',
                    query: { project: props.row.id },
                  })
                "
                size="18"
              />
            </span>
            <span style="align-self: center;" class="mx-2 mx-md-0">
              <feather-icon
                class="cursor-pointer"
                icon="TrashIcon"
                @click="deleteItem(props.row)"
                size="18"
              />
            </span>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <b-modal
      id="modal-create"
      cancel-variant="outline-secondary"
      ok-title="Создать"
      cancel-title="Закрыть"
      centered
      title="Создать проект"
      @ok="$emit('createItem', createProjectName)"
    >
      <b-form>
        <b-form-group>
          <label for="name">Название:</label>
          <b-form-input
            id="name"
            placeholder="Введите название"
            v-model="createProjectName"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-rename"
      cancel-variant="outline-secondary"
      ok-title="Переименовать"
      cancel-title="Закрыть"
      centered
      title="Переименовать проект"
      @ok="$emit('renameItem', projectToRename)"
    >
      <b-form>
        <b-form-group>
          <label for="name">Название:</label>
          <b-form-input
            id="name"
            placeholder="Введите название"
            v-model="projectToRename.name"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal id="modal-widget" hide-footer centered>
      <template #modal-title>
        Код для встави виджета
      </template>
      <div class="d-block text-center">
        <b-row>
          <b-col md="12" class="mb-2">
            <label for="script-copy">
              Скопируйте внутрь {{ "<" }}body{{ ">" }}
            </label>
            <div style="position: relative">
              <feather-icon
                icon="ClipboardIcon"
                style="position: absolute;top: 10px; right: 18px;"
                size="21"
                @click="doCopy(scriptString)"
              />
              <b-form-textarea v-model="scriptString" readonly no-resize>
              </b-form-textarea>
            </div>
          </b-col>
          <b-col md="12" class="mb-2">
            <label for="script-copy">
              Вставьте виджет там, где вам удобно:
            </label>
            <b-tabs align="center">
              <b-tab>
                <template #title>
                  <span>Виджетом</span>
                </template>
                <div style="position: relative">
                  <b-form-textarea
                    v-model="inputString"
                    rows="5"
                    readonly
                    no-resize
                  >
                  </b-form-textarea>
                  <feather-icon
                    icon="ClipboardIcon"
                    style="position: absolute;top: 10px; right: 18px;"
                    size="21"
                    @click="doCopy(inputString)"
                  />
                </div>
              </b-tab>
              <b-tab>
                <template #title>
                  <span>В тело страницы</span>
                </template>
                <div style="position: relative">
                  <b-form-textarea
                    v-model="bodyInputString"
                    rows="5"
                    readonly
                    no-resize
                  >
                  </b-form-textarea>
                  <feather-icon
                    icon="ClipboardIcon"
                    style="position: absolute;top: 10px; right: 18px;"
                    size="21"
                    @click="doCopy(bodyInputString)"
                  />
                </div>
              </b-tab>
            </b-tabs>
          </b-col>
          <b-col md="12">
            <label for="script-copy">
              ЛИБО, отправьте прямую ссылку на виджет:
            </label>
            <div style="position: relative">
              <b-form-textarea
                v-model="directString"
                rows="5"
                readonly
                no-resize
              >
              </b-form-textarea>
              <feather-icon
                v-if="directString"
                icon="ClipboardIcon"
                style="position: absolute;top: 10px; right: 18px;"
                size="21"
                @click="doCopy(directString)"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-button class="mt-3" block @click="$bvModal.hide('modal-widget')"
        >Закрыть</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BForm,
  VBPopover,
  BPopover,
  BFormTextarea,
  BCol,
  BRow,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";

import { scriptString, widgetString } from "../edit-project/code";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VueGoodTable,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BForm,
    BPopover,
    BFormTextarea,
    BCol,
    BRow,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
    "b-popover": VBPopover,
  },
  props: ["projects", "isDisabled"],
  data() {
    return {
      dir: false,
      columns: [
        {
          label: "Название",
          field: "name",
        },
        {
          label: "Показов",
          field: "shows",
        },
        {
          label: "",
          field: "action",
        },
      ],
      rows: [],
      createProjectName: "",
      scriptString,
      inputString: "",
      bodyInputString: "",
      directString: "",
      projectToRename: {
        name: "",
      },
    };
  },
  computed: {
    ...mapGetters("projects", ["getProjects"]),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    deleteItem(row) {
      this.$swal({
        title: "Вы действительно хотите удалить проект?",
        text: "После удаления, его невозможно будет восстановить",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Да, удалить!",
        cancelButtonText: "Отменить",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$emit("deleteItem", row);
        }
      });
    },
    widgetInputString(id) {
      const baseUrl =
        process.env.VUE_APP_DOMAIN || "https://video-quest-dev.herokuapp.com/";
      this.inputString = `<iframe id="video-quest" src="${baseUrl}vq/${id}" width="120" height="210" frameBorder="0"></iframe>`;
      this.bodyInputString = `<iframe id="vq-body" src="${baseUrl}body/${id}" width="120" height="210" frameBorder="0"></iframe>`;
    },
    directInputString(id) {
      const baseUrl =
        (process.env.VUE_APP_DOMAIN ||
          "https://video-quest-dev.herokuapp.com/") + "direct/";
      this.directString = `${baseUrl}${id}`;
    },
    modalRename(id) {
      this.projectToRename = this.getProjects.filter(
        (item) => item.id === id
      )[0];
      this.$bvModal.show("modal-rename");
    },
    showWidgetModal(id) {
      this.widgetInputString(id);
      this.directInputString(id);
      this.$bvModal.show("modal-widget");
    },
    doCopy(string) {
      window.prompt("Скопируйте в буффер обмена: Ctrl+C, Enter", string);
    },
  },
  created() {
    this.rows = this.projects;
  },
};
</script>

<style lang="scss">
.projects-table tr:hover {
  background-color: #f8f8f8;
}
</style>
