<template>
  <div>
    <overview-table
      :projects="getProjects"
      :isDisabled="getIsDisabled"
      @deleteItem="deleteItem"
      @createItem="createItem"
      @renameItem="renameItem"
    />
  </div>
</template>

<script>
import OverviewTable from "./OverviewTable.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  metaInfo: {
    title: "moovee",
    titleTemplate: "%s - Проекты",
    htmlAttrs: {
      lang: "ru",
      amp: true,
    },
  },
  components: {
    OverviewTable,
  },
  computed: {
    ...mapGetters("projects", ["getProjects"]),
    ...mapGetters("user", ["getIsDisabled"]),
  },
  methods: {
    ...mapActions("projects", [
      "requestProjects",
      "deleteProject",
      "createProject",
      "createSource",
      "renameProject",
    ]),
    ...mapActions("user", ["requestUserData"]),
    deleteItem(item) {
      this.deleteProject(item);
    },
    async createItem(name) {
      const project = await this.createProject(name);
      this.createSource({
        title: "Новая сцена",
        projectId: project.id,
      });
      this.$router.push({
        name: "project-edit",
        params: { id: project.id, name: project.name },
      });
    },
    renameItem(project) {
      this.renameProject(project);
    },
  },
  mounted() {
    this.requestProjects();
    this.requestUserData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
